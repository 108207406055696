import { ChangeDetectionStrategy, Component, NgZone, OnInit } from "@angular/core";
import { KeycloakService } from "src/app/services/keycloak.service";
import { DisplayMode, getIsDisplayMode } from "src/app/utils/router.utils";

@Component({
  selector: "app-footer",
  template: "",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FooterComponent implements OnInit {
  constructor(
    public readonly keycloakService: KeycloakService,
    private readonly ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(async () => {
      const isAuthenticated = this.keycloakService.authenticated();
      const isDisplayModeApp = getIsDisplayMode(DisplayMode.App);

      if (isAuthenticated && !isDisplayModeApp) {
        await window.micro.footer.init();
      }
    });
  }
}
