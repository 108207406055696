import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { KeycloakService } from "src/app/services/keycloak.service";

import { AsyncPipe } from "@angular/common";
import { map } from "rxjs/operators";
import { KEYCLOAK_FMS_INFO_KEY, KEYCLOAK_NO_RESOURCE_ROLE } from "src/app/list-advisor-accesses/constants";
import { createKeyCloakFmsKey } from "src/app/list-advisor-accesses/createKeyCloakFmsKey";
import { FmsService } from "src/app/services/fms.service";
import { getIsEmpty } from "src/app/utils/array";

@Component({
  selector: "app-list-advisor-accesses",
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: "./list-advisor-accesses.component.html",
  styleUrl: "./list-advisor-accesses.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListAdvisorAccessesComponent {
  readonly advisorResourceRoles$: Observable<string[]>;
  readonly roleInfo$: Observable<string>;

  constructor(
    public keycloakService: KeycloakService,
    public fmsService: FmsService,
  ) {
    this.roleInfo$ = fmsService.translateAsync(KEYCLOAK_FMS_INFO_KEY);
    this.advisorResourceRoles$ = this.keycloakService.parsedIdToken$.pipe(
      map((parsedIdToken) => {
        const roles = parsedIdToken?.on_behalf_of?.resource_role ?? [];
        if (getIsEmpty(roles)) {
          return [KEYCLOAK_NO_RESOURCE_ROLE];
        }
        return roles.map((role: string) => createKeyCloakFmsKey(role));
      }),
    );
  }
}
