import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from "@angular/router";
import { Observable, Subject, filter, map, startWith } from "rxjs";
import { monitoredDeprecatedUrls } from "src/app/app.routing";
import { ErrorsService } from "src/app/services/errors.service";
import { StorebrandOnlyRouterWatcherService } from "src/app/services/storebrand-only-router-watcher.service";
import { RedirectWarning } from "src/app/utils/errors";
import { Monitoring } from "src/app/utils/monitoring";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { CookieService, Cookies } from "./services/cookie.service";
import { EngagementsService } from "./services/engagements.service";
import { FeatureToggleService } from "./services/feature-toggle.service";
import { LeadService } from "./services/leads/lead.service";
import { MigrateOtherPensionToSinglePeriodService } from "./services/migrate-other-pension-to-single-period.service";
import { MigrateSimulationParametersService } from "./services/migrate-simulation-parameters.service";
import { PublicPensionConsentsPrognosisTriggerService } from "./services/prognoses-services/public-pension-consents-prognosis-trigger.service";
import {
  DisplayMode,
  GlobalQueryParam,
  getIsNavigationEnd,
  getIsNavigationEndOrCancelOrError,
} from "./utils/router.utils";
import { isNotStageP } from "./utils/storebrand-staging";
import { DatadogService } from "./services/datadog.service";
import { MenuComponent } from "src/app/modules/shared/components/menu/menu.component";
import { DevComponent } from "src/app/modules/shared/components/dev/dev.component";
import { ErrorsPageComponent } from "src/app/core/pages/errors-page/errors-page.component";
import { RoleErrorsComponent } from "src/app/core/pages/role-errors/role-errors.component";
import { BreadcrumbsComponent } from "src/app/modules/shared/components/breadcrumbs/breadcrumbs.component";
import { FooterComponent } from "src/app/modules/shared/components/footer/footer.component";
import { RefreshModalHostComponent } from "src/app/modules/shared/components/refresh-modal/refresh-modal.component";
import { MatLegacyDialog, MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { AsyncPipe } from "@angular/common";
import { INCOME_STRATEGIES, IncomeService } from "src/app/services/income/income.service";
import { IncomePublicPension } from "src/app/services/income/strategies/income-public-pension";
import { IncomeEmployer } from "src/app/services/income/strategies/income-employer";
import { IncomeUser } from "src/app/services/income/strategies/income-user";
import { GlobalRunningJobsService } from "src/app/services/running-jobs/global-running-jobs.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    MenuComponent,
    DevComponent,
    ErrorsPageComponent,
    RoleErrorsComponent,
    BreadcrumbsComponent,
    FooterComponent,
    RefreshModalHostComponent,
    AsyncPipe,
    MatLegacyDialogModule,
  ],
  providers: [
    CookieService,
    ErrorsService,
    GlobalRunningJobsService,
    StorebrandOnlyRouterWatcherService,
    EngagementsService,
    FeatureToggleService,
    LeadService,
    MigrateOtherPensionToSinglePeriodService,
    MigrateSimulationParametersService,
    PublicPensionConsentsPrognosisTriggerService,
    DatadogService,
    IncomeService,
    IncomePublicPension,
    IncomeEmployer,
    IncomeUser,
    MatLegacyDialog,
    {
      provide: INCOME_STRATEGIES,
      useFactory: (publicPension: IncomePublicPension, employer: IncomeEmployer, user: IncomeUser) => {
        return [publicPension, employer, user];
      },
      deps: [IncomePublicPension, IncomeEmployer, IncomeUser],
    },
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly showBreadcrumbs$: Observable<boolean>;
  public readonly showDevPanel$: Observable<boolean>;
  public readonly navigationEnd$: Observable<NavigationEnd | NavigationCancel | NavigationError>;
  public readonly hasCriticalErrors$: Observable<boolean>;
  public readonly hasRoleErrors$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly cookieService: CookieService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly leadService: LeadService,
    private readonly migrateOtherPensionToSinglePeriodService: MigrateOtherPensionToSinglePeriodService,
    private readonly migrateSimulationParametersService: MigrateSimulationParametersService,
    public readonly errorsService: ErrorsService,
    private readonly storebrandOnlyRouterWatcherService: StorebrandOnlyRouterWatcherService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly engagementsService: EngagementsService,
    private readonly publicPensionConsentsPrognosisTriggerService: PublicPensionConsentsPrognosisTriggerService,
    private readonly datadogService: DatadogService,
  ) {
    this.showDevPanel$ = this.cookieService
      .watch$(Cookies.ActivateDevPanel)
      .pipe(map((val) => val === "true" || isNotStageP()));

    this.hasCriticalErrors$ = this.errorsService.hasCriticalErrors$.pipe(startWith(false));
    this.hasRoleErrors$ = this.errorsService.hasRoleErrors$.pipe(startWith(false));

    this.navigationEnd$ = memoizeObject$(this.router.events.pipe(filter(getIsNavigationEndOrCancelOrError)));

    this.showBreadcrumbs$ = this.getShowBreadcrumbs(this.navigationEnd$);

    this.datadogService.init();
  }

  public ngOnInit(): void {
    this.warnAboutDeprecatedUrl(this.navigationEnd$);

    this.featureToggleService.init(this.destroy$).subscribe();
    this.leadService.beginPostingLeadsForRegistrationSavingsAndAfp().subscribe();
    this.migrateOtherPensionToSinglePeriodService.init();
    this.migrateSimulationParametersService.init();
    this.storebrandOnlyRouterWatcherService.init();
    this.engagementsService.beginPersistingPrognosisParametersByEngagement().subscribe();
    this.publicPensionConsentsPrognosisTriggerService.evictPublicPensionPrognosisOnNewConsents().subscribe();
    this.publicPensionConsentsPrognosisTriggerService.evictAndFetchParametersOnOfaConsent().subscribe();

    setTimeout(() => {
      faceSwap();
    }, 100);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Monitor certain redirects so we're able to catch
   * and fix old/deprecated links that may be removed in the future
   */
  private warnAboutDeprecatedUrl(routerEvent$: Observable<Event>): void {
    routerEvent$
      .pipe(
        filter(getIsNavigationEnd),
        filter(({ url }) => monitoredDeprecatedUrls.some((route) => url.includes(route))),
      )
      .subscribe((event) => {
        Monitoring.warn(
          new RedirectWarning(`[AppComponent::warnAboutDeprecatedUrl] ${event.url} => ${event.urlAfterRedirects}`),
        );
      });
  }

  private getShowBreadcrumbs(event$: Observable<Event>): Observable<boolean> {
    return event$.pipe(
      map(() => this.activatedRoute.snapshot.queryParamMap.get(GlobalQueryParam.DisplayMode)),
      map((displayMode) => displayMode !== DisplayMode.App),
    );
  }
}
