import { enableProdMode } from "@angular/core";
import { bootloader, hmrModule } from "@angularclass/hmr";
import { getActiveTransaction } from "@sentry/angular-ivy";
import { loadMacroContainer } from "src/app/utils/external-scripts";
import { getStbStage, isStageLocalhost } from "src/app/utils/storebrand-staging";
import { Log } from "./app/utils/log";
import { Monitoring } from "./app/utils/monitoring";
import { environment } from "./environments/environment";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { appConfig } from "src/app/app.config";
import { setAppInjector } from "src/app/injector";

if (!isStageLocalhost()) {
  Monitoring.init({ dsn: environment.dsn, release: environment.sentryRelease }, 4);
}
Log.setLogLevel(environment.logLevel);

loadMacroContainer(getStbStage());

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  bootstrapApplication(AppComponent, appConfig)
    .then((appRef) => setAppInjector(appRef.injector))
    .catch((error) => {
      console.log("Error during Angular bootstrapping process", error);
    });

if (environment.hmr) {
  if (module["hot"]) {
    bootloader(() => {
      module?.hot?.accept();
      bootstrap().then((mod) => hmrModule(mod, module));
    });
  } else {
    Log.warn("HMR is not enabled for webpack-dev-server!");
    Log.warn("Are you using the --hmr flag for ng serve?");
  }
} else {
  // Track the duration of Angular bootstrapping process
  const activeTransaction = getActiveTransaction();
  const bootstrapSpan =
    activeTransaction &&
    activeTransaction.startChild({
      description: "platform-browser-dynamic",
      op: "angular.bootstrap",
    });

  bootstrap()
    .catch((err) => Monitoring.error(err, { ignore: isStageLocalhost() }))
    .finally(() => {
      if (bootstrapSpan) {
        bootstrapSpan.finish();
      }
    });
}
