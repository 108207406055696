import { Component, Input } from "@angular/core";
import { DisplayableHttpError } from "src/app/services/errors.types";
import { MatLegacyCardModule } from "@angular/material/legacy-card";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
  standalone: true,
  imports: [MatLegacyCardModule],
})
export class ErrorComponent {
  @Input()
  error!: DisplayableHttpError;
}
