import { Routes } from "@angular/router";
import { RouteKey, routes } from "./routes";

export const articlesLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/articles-overview-page/articles-overview-page.component").then(
        (c) => c.ArticlesOverviewPageComponent,
      ),
  },
  {
    path: routes[RouteKey.Article],
    children: [
      {
        path: routes.pensionReturnsIn2023,
        loadComponent: () =>
          import("./pages/pension-returns-in-2023/pension-returns-in-2023.component").then(
            (c) => c.PensionReturnsIn2023Component,
          ),
      },
      {
        path: routes.backPayments,
        loadComponent: () =>
          import("./pages/tilbakebetale-pensjon/tilbakebetale-pensjon.component").then(
            (c) => c.AppArticleBackPaymentsComponent,
          ),
      },
      {
        path: "**",
        loadComponent: () => import("./pages/article-page/article-page.component").then((c) => c.ArticlePageComponent),
      },
    ],
  },
];
