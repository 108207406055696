import { firstValueFrom } from "rxjs";
import { FAILED_LOGIN_REDIRECT_URL } from "src/app/constants/auth.constants";
import { KeycloakService } from "src/app/services/keycloak.service";
import { environment } from "src/environments/environment";
import { Monitoring } from "../utils/monitoring";
import { pruneProps } from "../utils/object";
import { select } from "../utils/rxjs/select";

export function keycloakInitializer(keycloakService: KeycloakService): () => Promise<void> {
  return (): Promise<void> =>
    new Promise(async (resolve) => {
      /**
       *
       * Prevent Keycloak from being re-initialized when using HMR. Re-initializing
       * Keycloak erases the token and consequently reloads the page.
       */
      if (environment.offlineMode || (environment.hmr && keycloakService.instance && keycloakService.authenticated())) {
        resolve();
        return;
      }

      try {
        await keycloakService.init();
        await keycloakService.login();

        Monitoring.setUser(
          pruneProps({
            id: select(keycloakService.cmid.user$),
            authMethod: select(keycloakService.amrFromToken$),
            advisor: select(keycloakService.advisorSignature$),
          }),
        );

        resolve();
      } catch {
        const isAdvisor = await firstValueFrom(keycloakService.isAdvisorContext$);
        if (!isAdvisor) {
          window.location.href = FAILED_LOGIN_REDIRECT_URL;
        }
      }
    });
}
