import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { Observable, of } from "rxjs";
import { NavigateToPensionPlanService } from "src/app/services/navigate-to-pension-plan.service";

export const redirectLastVistedUrlGuardResolver: ResolveFn<Observable<boolean>> = async () => {
  inject(NavigateToPensionPlanService).navigateToProgressUrl();

  return of(true);
};
