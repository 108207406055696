import { Routes } from "@angular/router";

export const contractsLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/contracts-page/contracts-page.component").then((c) => c.ContractsPageComponent),
    data: {
      title: "pageTitle.myPension",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
];
