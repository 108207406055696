import { Routes } from "@angular/router";
import {
  actionsLazyRoutes,
  RouteKey as ActionsRouteKey,
  routes as actionsRoutes,
} from "src/app/modules/actions/actions.routing";
import { RouteKey as ArticlesRouteKey, routes as articleRoutes } from "src/app/modules/articles/routes";
import { RouteKey as ContractsRouteKey, routes as contractsRoutes } from "src/app/modules/contracts/routes";
import {
  RouteKey as InvestmentOptionsRouteKey,
  routes as investmentOptionsRoutes,
} from "src/app/modules/investment-options/routes";
import { needsLazyRoutes, RouteKey as NeedsRouteKey, routes as needsRoutes } from "src/app/modules/needs/needs.routing";
import {
  managePkbPagesLazyRoutes,
  RouteKey as ManagePkbRouteKey,
  routes as managePkbRoutes,
} from "src/app/modules/pages/manage-pkb-pages/manage-pkb-pages.routing";
import {
  RouteKey as PlanRouteKey,
  deprecatedRoutes as planDeprecatedRoutes,
  routes as planRoutes,
} from "src/app/modules/pension-plan/routes";
import { RouteKey as SmartAccountRouteKey, routes as smartAccountRoutes } from "src/app/modules/smart-account/routes";
import { navigateToPensionPlanResolver } from "src/app/resolvers/navigate-to-pension-plan.resolver";
import { BreadcrumbKey } from "src/app/services/breadcrumbs.service";
import { environment } from "src/environments/environment";
import { canActivateApp } from "./guards/auth.guard";
import { PageNotFoundComponent } from "./modules/shared/components/pagenotfound/pagenotfound.component";
import { smartAccountResolver } from "./modules/smart-account/resolvers/smart-account.resolver";
import {
  apiHomeResolver,
  contractSalaryResolver,
  fmsResolver,
  portfolioInsightResolver,
} from "./resolvers/app.resolver";
import {
  afpResolver,
  bankResolver,
  navResolver,
  norskpensjonResolver,
  otherPensionEngagementsResolver,
  publicPensionResolver,
  savingsAndPensionResolver,
  unavailableNorskpensjonResolver,
} from "./resolvers/engagement.resolver";
import { removeAfpInPublicContextResolver } from "./resolvers/remove-afp-in-public-context.resolver";
import {
  benefitsResolver,
  consentResolver,
  customerResolver,
  customerSuppliedClientDataResolver,
  customerSuppliedDataResolver,
  languageResolver,
} from "./resolvers/user.resolver";
import { RouteKey, routes } from "./routes";
import {
  RouteKey as SavingForPensionKey,
  routes as savingForPensionRoutes,
} from "src/app/modules/saving-for-pension/routes";
import { contractsLazyRoutes } from "src/app/modules/contracts/contracts.routing";
import { pensionPlanLazyRoutes } from "src/app/modules/pension-plan/pension-plan.routing";
import { smartAccountLazyRoutes } from "src/app/modules/smart-account/smart-account.routing";
import { investmentOptionsLazyRoutes } from "src/app/modules/investment-options/investment-options.routing";
import { articlesLazyRoutes } from "src/app/modules/articles/articles.routing";
import { savingForPensionLazyRoutes } from "src/app/modules/saving-for-pension/saving-for-pension.routing";
/**
 * Renamed or deprecated routes for backward compability.
 * A visit to any route in this list will trigger a monitor warning, which is
 * useful for catching old links spread around both inside and outside
 * the application.
 */
export const monitoredDeprecatedUrls: string[] = Object.values(planDeprecatedRoutes).map(
  (value) => `${planRoutes[PlanRouteKey.Root]}/${value}`,
);

export const appRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        resolve: {
          apiHomeResolver,
        },
        children: [
          {
            path: "",
            canActivateChild: environment.offlineMode ? [] : [canActivateApp],
            resolve: [],
            children: [
              {
                path: "",
                resolve: {
                  customerResolver,
                  contractSalaryResolver,
                  languageResolver,
                },
                children: [
                  {
                    path: "",
                    resolve: {
                      consentResolver,
                      fmsResolver,
                    },
                    children: [
                      {
                        path: "",
                        resolve: {
                          customerSuppliedDataResolver,
                          customerSuppliedClientDataResolver,
                        },
                        children: [
                          {
                            path: "",
                            resolve: {
                              removeAfpInPublicContextResolver,
                              afpResolver,
                              bankResolver,
                              benefitsResolver,
                              navResolver,
                              norskpensjonResolver,
                              otherPensionEngagementsResolver,
                              publicPensionResolver,
                              savingsAndPensionResolver,
                              smartAccountResolver,
                              unavailableNorskpensjonResolver,
                              portfolioInsightResolver,
                            },
                            children: [
                              {
                                path: routes[RouteKey.Root],
                                resolve: { navigateToPensionPlanResolver },
                                loadComponent: () =>
                                  import("./core/pages/dashboard/dashboard.component").then(
                                    (c) => c.DashboardComponent,
                                  ),
                              },
                              {
                                path: routes[RouteKey.Root],
                                data: {
                                  breadcrumb: {
                                    key: BreadcrumbKey.PensionPlan,
                                  },
                                },
                                children: [
                                  {
                                    path: routes[RouteKey.ContactUs],
                                    loadComponent: () =>
                                      import("./core/pages/contact-us-page/contact-us-page.component").then(
                                        (c) => c.ContactUsPageComponent,
                                      ),
                                  },
                                  {
                                    path: routes[RouteKey.Concept],
                                    loadComponent: () =>
                                      import("./core/pages/concept-page/concept-page.component").then(
                                        (c) => c.ConceptPageComponent,
                                      ),
                                  },
                                  {
                                    path: planRoutes[PlanRouteKey.Root],
                                    children: pensionPlanLazyRoutes,
                                  },
                                  {
                                    path: smartAccountRoutes[SmartAccountRouteKey.Root],
                                    children: smartAccountLazyRoutes,
                                  },
                                  {
                                    path: investmentOptionsRoutes[InvestmentOptionsRouteKey.Root],
                                    data: {
                                      breadcrumb: {
                                        hideAll: true,
                                      },
                                    },
                                    children: investmentOptionsLazyRoutes,
                                  },
                                  {
                                    path: articleRoutes[ArticlesRouteKey.Root],
                                    children: articlesLazyRoutes,
                                  },
                                  {
                                    path: contractsRoutes[ContractsRouteKey.Root],
                                    children: contractsLazyRoutes,
                                  },
                                  {
                                    path: actionsRoutes[ActionsRouteKey.Root],
                                    children: actionsLazyRoutes,
                                  },
                                  {
                                    path: needsRoutes[NeedsRouteKey.Root],
                                    children: needsLazyRoutes,
                                  },
                                  {
                                    path: managePkbRoutes[ManagePkbRouteKey.Root],
                                    children: managePkbPagesLazyRoutes,
                                  },
                                  {
                                    path: savingForPensionRoutes[SavingForPensionKey.Root],
                                    children: savingForPensionLazyRoutes,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "**",
                component: PageNotFoundComponent,
                data: {
                  breadcrumb: {
                    key: BreadcrumbKey.PensionPlan,
                    path: "/",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
