import { Component, OnInit } from "@angular/core";
import { GlobalRunningJobsService } from "src/app/services/running-jobs/global-running-jobs.service";
import { Monitoring } from "src/app/utils/monitoring";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";
import { TrackIdDirective } from "../../directives/track-id/track-id.directive";
import { ButtonModule } from "@storeblocks/button-ng";
import { RouterLink } from "@angular/router";
import { FmsPipe } from "../../pipes/fms.pipe";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./pagenotfound.component.html",
  styleUrls: ["./pagenotfound.component.scss"],
  standalone: true,
  imports: [TrackIdDirective, ButtonModule, RouterLink, FmsPipe],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private readonly globalRunningJobsService: GlobalRunningJobsService) {
    this.globalRunningJobsService.forceStop();
  }

  ngOnInit(): void {
    const location = window.location;
    Monitoring.info(`404 on location: ${location}`, { ignore: isStageLocalhost() });
  }
}
