import { Routes } from "@angular/router";

export const savingForPensionLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/saving-for-pension-page/saving-for-pension-page.component").then(
        (c) => c.SavingForPensionPageComponent,
      ),
  },
];
