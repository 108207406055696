<div (click)="toggleDevPanel()" class="devmode-indicator">
  {{ devModeIndicator$ | async }}
</div>
@if (devPanelToggle) {
  <div class="dev">
    <div class="buttons-container">
      <div class="toggles-container">
        <mat-button-toggle (click)="toggleEnv()">Env</mat-button-toggle>
        <mat-button-toggle (click)="toggleRemoteConfig()">RemoteConfig</mat-button-toggle>
        <mat-button-toggle (click)="toggleCustomer()">Customer</mat-button-toggle>
        <mat-button-toggle (click)="toggleConsents()">Consents</mat-button-toggle>
        <mat-button-toggle (click)="toggleSimParams()">Sim-params</mat-button-toggle>
        <mat-button-toggle (click)="toggleProfile()">Profile</mat-button-toggle>
        <mat-button-toggle (click)="toggleClientData()">ClientData</mat-button-toggle>
        <mat-button-toggle (click)="toggleKeycloakToken()" [disabled]="offlineMode"> KC Tok</mat-button-toggle>
        <mat-button-toggle (click)="toggleAdvisorMode()">Advisor</mat-button-toggle>
        <mat-button-toggle (click)="toggleSessionStorage()">SessionStorage</mat-button-toggle>
        <mat-button-toggle (click)="toggleLifeExpectancy()">LifeExpectancy</mat-button-toggle>
        <mat-button-toggle (click)="togglePublicPensionData()">PublicPensionData</mat-button-toggle>
        <mat-button-toggle (click)="showFeatureTogglesPanel()">FeatureToggles</mat-button-toggle>
        <app-api-fetch-menu></app-api-fetch-menu>
        <button mat-flat-button (click)="copyToken()" [disabled]="offlineMode">Copy token</button>
        <button mat-flat-button (click)="generateDummySentryError()">Generate Sentry Error</button>
        <button
          [ngClass]="{ enabled: dispatchLogging, disabled: !dispatchLogging }"
          mat-flat-button
          (click)="toggleDispatcherLogging()"
        >
          Dispatch Logging
        </button>
        <h3 class="nin white-text" (click)="copyNin()">NIN: {{ nin$ | async }}</h3>
      </div>
      <div class="icon-settings-container">
        <app-pin [toggle]="stickyToggle$ | async" (click)="toggleSticky()" theme="dark"></app-pin>
        <img
          src="https://elements.storebrand.no/din-pensjonsplan/icons-svg/streamline-regular/01-interface-essential/cog.svg"
          alt
          style="filter: invert(1); width: 1.5em"
          (click)="toggleUserSettingsPanel()"
        />
      </div>
    </div>
    <mat-form-field class="dropdown">
      <mat-select
        panelClass="large-dropdown-panel"
        placeholder="Gå til ->"
        (selectionChange)="navigateToPage($event.value)"
      >
        @for (route of pages | keyvalue; track route) {
          <mat-option [value]="route.value">
            {{ route.key }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dropdown">
      <mat-select
        panelClass="large-dropdown-panel"
        placeholder="pensionPlan page"
        [value]="currentPensionPlanRoute$ | async"
        (selectionChange)="router.navigate([$event.value], { queryParamsHandling: 'merge' })"
      >
        @for (route of pensionPlanRoutes$ | async; track route) {
          <mat-option [value]="route.url">
            {{ route.key }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dropdown">
      <mat-select
        placeholder="smartAccount state"
        [value]="SmartAccountState[(smartAccountService.smartAccountState$ | async)!]"
        (selectionChange)="smartAccountStateSelect($event)"
      >
        @for (stateKey of smartAccountStateKeys(); track stateKey) {
          <mat-option [value]="stateKey">
            {{ stateKey }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dropdown">
      <mat-select
        placeholder="extrapension state"
        [value]="ExtrapensionState[(extrapensionService.extrapensionState$ | async)!]"
        (selectionChange)="extrapensionStateSelect($event)"
      >
        @for (stateKey of extrapensionStateKeys(); track stateKey) {
          <mat-option [value]="stateKey">
            {{ stateKey }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dropdown">
      <mat-select placeholder="ips state" [value]="ipsStateValue$ | async" (selectionChange)="ipsStateSelect($event)">
        @for (stateKey of ipsStateValues(); track stateKey) {
          <mat-option [value]="stateKey">
            {{ stateKey }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button mat-flat-button (click)="stepUpAuth()">
      {{ "Step Up Auth" }}
    </button>
    <button mat-flat-button (click)="logout()">
      {{ "header.navigation.log_off.text" | fms }}
    </button>
    <button mat-flat-button (click)="setLogoutCookie()">Set QSI Logout Cookies</button>
    <a href="../smart-pensjon/pensjonsplan/mine-penger?displaymode=app">
      <button mat-flat-button>Gå til Mine Penger</button>
    </a>
    <div class="row">
      <div class="spinner-stack">
        <div class="spinner-headline-wrapper">
          <p class="bold white-text">GlobalSpinner</p>
          <button (click)="globalRunningJobsService.forceStop()">pop</button>
        </div>
        {{ globalRunningJobsService.runningJobs$ | async | json }}
      </div>
      <div class="spinner-stack">
        <div class="spinner-headline-wrapper">
          <p class="bold white-text">PrognosesSpinner</p>
          <button (click)="fetchPrognosesRunningJobsService.forceStop()">pop</button>
        </div>
        {{ prognosesSpinner$ | async | json }}
      </div>
    </div>
    @if (errorsService.hasCriticalErrors$ | async) {
      <div class="spinner-reload">
        @for (e of this.errorsService.criticalErrors$ | async; track e) {
          <app-error [error]="e"></app-error>
        }
        <button mat-flat-button color="primary" (click)="refreshClicked()">
          {{ "errorspage.button.reload" | fms }}
        </button>
      </div>
    }
    @if (envToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ env | json }}</pre>
      </div>
    }
    @if (remoteConfigToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ remoteConfig$ | async | json }}</pre>
      </div>
    }
    @if (customerToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ customer$ | async | json }}</pre>
      </div>
    }
    @if (consentsToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre *appLet="'Ikke tatt stilling til' as freshConsent">
          storageConsent: {{ (this.consentService.customerSuppliedDataConsent$ | async) ?? freshConsent }}
          norskPensjonConsent: {{ (this.consentService.hasNorskPensjonConsent$ | async) ?? freshConsent }}
          purchaseAssistanceConsentValue: {{
            (this.consentService.purchaseAssistanceConsentValue$ | async) ?? freshConsent
          }}
          navConsentValue: {{ (this.customerService.navConsent$ | async) ?? freshConsent }}
          ofaConsentValue: {{ (this.customerService.ofaConsent$ | async) ?? freshConsent }}
          extractAllConsents(): {{ this.consentService.extractAllConsents() | json }}
          </pre
        >
      </div>
    }
    @if (simParamsToggle) {
      <div class="data-panel simulation-params" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>
            annualGrossIncome: {{ this.incomeService.annualGrossIncome$ | async }}
          startPayoutAge: {{ startPayoutAge$ | async }}
          startPayoutAgePartialWithdrawal: {{ startPayoutAgePartialWithdrawal$ | async }}
          startPayoutAgeRange: {{ startPayoutAgeRange$ | async }}
          partTimePercentage: {{ partTimePercentage$ | async }}
          withdrawalPercentage: {{ withdrawalPercentage$ | async }}
          postPensionPartTimeEnable: {{ this.commonParametersService.postPensionPartTimeEnable$ | async }}
          postPensionPartTimeEndAge: {{ this.commonParametersService.postPensionPartTimeEndAge$ | async }}
          postPensionPartTimePercent: {{ this.commonParametersService.postPensionPartTimePercent$ | async }}</pre
        >
      </div>
    }
    @if (profileToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ profile$ | async | json }}</pre>
      </div>
    }
    @if (clientDataToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ clientData$ | async | json }}</pre>
      </div>
    }
    @if (keyCloakTokenToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ keycloakService.parsedIdToken$ | async | json }}</pre>
      </div>
    }
    @if (sessionStorageToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ sessionStorageService.getAppData() | json }}</pre>
      </div>
    }
    @if (lifeExpectancyToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ lifeExpectancyData$ | async | json }}</pre>
      </div>
    }
    @if (publicPensionDataToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ publicPensionData$ | async | json }}</pre>
      </div>
    }
    <ng-container *appLet="featureToggles$ | async | keyvalue as featureToggles">
      @if (showFeatureTogglesPanelToggle) {
        <div class="data-panel" cdkDrag>
          <div class="drag-handle" cdkDragHandle></div>
          @for (featureToggle of featureToggles; track featureToggle) {
            <div class="clickable" (click)="toggleFeatureOverride(featureToggle.key)">
              <stb-checkbox [fluid]="true">
                <input type="checkbox" [checked]="featureToggle.value === true" />
                <label>{{ featureToggle.key }}</label>
              </stb-checkbox>
            </div>
          }
        </div>
      }
    </ng-container>
  </div>
}

@if (isOpenUserSettings$ | async) {
  <app-user-settings-panel></app-user-settings-panel>
}
