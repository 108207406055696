import { ChangeDetectionStrategy, Component, NgZone, OnInit } from "@angular/core";
import {
  MatLegacyCheckboxChange as MatCheckboxChange,
  MatLegacyCheckboxModule,
} from "@angular/material/legacy-checkbox";
import { firstValueFrom } from "rxjs";
import { KeycloakService } from "src/app/services/keycloak.service";
import { QualtricsService } from "src/app/services/qualtrics.service";
import { DisplayMode, getIsDisplayMode } from "src/app/utils/router.utils";
import { ListAdvisorAccessesComponent } from "../../../../list-advisor-accesses/list-advisor-accesses.component";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyCheckboxModule, ListAdvisorAccessesComponent, AsyncPipe],
})
export class MenuComponent implements OnInit {
  public readonly isAdvisorContext$ = this.keycloakService.isAdvisorContext$;
  public readonly advisorIncognitoMode$ = this.keycloakService.getAdvisorIncognitoMode();

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly qualtricsService: QualtricsService,
    private readonly ngZone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.ngZone.runOutsideAngular(async () => {
      const isAuthenticated = this.keycloakService.authenticated();
      const isDisplayModeApp = getIsDisplayMode(DisplayMode.App);
      const isAdvisorContext = await firstValueFrom(this.keycloakService.isAdvisorContext$);

      if (isAuthenticated && !isDisplayModeApp && !isAdvisorContext) {
        await window.micro.navbar.init("navbar-root", {
          login: async (): Promise<void> => {
            this.keycloakService.login();
          },
          logout: async (): Promise<void> => {
            this.qualtricsService.setCookies();
            await this.keycloakService.logout();
          },
          getToken: (): Promise<string | undefined> => this.keycloakService.getToken(),
        });
      }
    });
  }

  public toggleIncognitoMode(event: MatCheckboxChange): void {
    this.keycloakService.setAdvisorIncognitoMode(event.checked);
  }
}
