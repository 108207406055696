<div class="content-container">
  <app-menu></app-menu>

  @if (showDevPanel$ | async) {
    <app-dev class="hide-only-on-print"></app-dev>
  }

  <div class="flex">
    @if (hasCriticalErrors$ | async) {
      <app-error-page></app-error-page>
    } @else if (hasRoleErrors$ | async) {
      <app-role-error></app-role-error>
    } @else {
      <div class="outlet">
        @if (!outlet.isActivated) {
          <div class="page-init-spinner absolute-spinner"></div>
        }

        @if (outlet.isActivated) {
          <div class="container hide-only-on-print">
            @if (showBreadcrumbs$ | async) {
              <app-breadcrumbs></app-breadcrumbs>
            }
          </div>
        }

        <router-outlet id="router-outlet" tabindex="-1" #outlet="outlet"></router-outlet>
      </div>
    }
  </div>

  <app-footer></app-footer>
</div>

<app-refresh-modal></app-refresh-modal>
