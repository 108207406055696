@if (isVisible$ | async) {
  <div class="breadcrumbs">
    <div class="breadcrumb">
      <a [href]="'breadcrumbs.overview.url' | fms">
        {{ "breadcrumbs.overview" | fms }}
      </a>
      <i>chevron-right</i>
    </div>
    @for (breadcrumb of breadcrumbs$ | async; track breadcrumb.key) {
      <div class="breadcrumb">
        <a [routerLink]="[breadcrumb.path]" queryParamsHandling="merge">
          {{ breadcrumb.key | fms }}
        </a>
        <i>chevron-right</i>
      </div>
    }
  </div>
}
