import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Breadcrumb, BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { TrackIdDirective } from "../../directives/track-id/track-id.directive";
import { HrefDirective } from "../../directives/href.directive";
import { RouterLink } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { FmsPipe } from "../../pipes/fms.pipe";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TrackIdDirective, HrefDirective, RouterLink, AsyncPipe, FmsPipe],
})
export class BreadcrumbsComponent {
  public readonly breadcrumbs$ = this.breadcrumbsService.getBreadcrumbs();
  public readonly isVisible$ = this.getIsVisible();

  constructor(private readonly breadcrumbsService: BreadcrumbsService) {}

  private getIsVisible(): Observable<boolean> {
    return this.breadcrumbs$.pipe(map(getIsNoneHideAll));
  }
}

function getIsNoneHideAll(breadcrumbs: Breadcrumb[]): boolean {
  return breadcrumbs.every((breadcrumb) => !breadcrumb.hideAll);
}
