<details class="list-advisor-accesses">
  <summary>{{ this.fmsService.translateAsync("keycloak.info.heading") | async }}</summary>
  <p>{{ roleInfo$ | async }}</p>
  <ul>
    @for (role of advisorResourceRoles$ | async; track role) {
      <li>
        <p>
          {{ this.fmsService.translateAsync(role) | async }}
        </p>
      </li>
    }
  </ul>
</details>
