import { Component } from "@angular/core";
import { AlertKey } from "src/app/modules/shared/components/alerts/information-message/information-message.component";
import { InformationMessageComponent } from "../../../modules/shared/components/alerts/information-message/information-message.component";
import { TrackIdDirective } from "../../../modules/shared/directives/track-id/track-id.directive";
import { ButtonModule } from "@storeblocks/button-ng";
import { A11yClickDirective } from "../../../modules/shared/directives/a11y-click.directive";
import { FmsPipe } from "../../../modules/shared/pipes/fms.pipe";

@Component({
  selector: "app-error-page",
  templateUrl: "errors-page.component.html",
  styleUrls: ["errors-page.component.scss"],
  standalone: true,
  imports: [InformationMessageComponent, TrackIdDirective, ButtonModule, A11yClickDirective, FmsPipe],
})
export class ErrorsPageComponent {
  public readonly AlertKey = AlertKey;

  public reloadPage(): void {
    location.reload();
  }
}
